import type { CategoriesViewProps, CategoryProps, GameProps } from '../../types';

import * as React from 'react';
import dynamic from 'next/dynamic';
import { useInView } from 'react-intersection-observer';
import { Title } from '../../components/Title';
import { Link } from '../../components/Link';
import { CollectionSection } from '../../components/CollectionSection';
import { useAppContext } from '../../contexts/AppContext';
import Container from '@mui/material/Container';
import useLocalStorage from '../../utils/use-local-storage';
import { useTranslation } from 'react-i18next';
import useFlattenedGameData from '../../utils/useFlattenArray';

const CarouselLayout = dynamic<any>(() => import('./CarouselLayout').then((mod) => mod.CarouselLayout), {
  ssr: false,
});

export const CollectionsE = (props: CategoriesViewProps) => {
  const { collections } = props;
  const { ref, inView } = useInView();
  const { t } = useTranslation('collections_e');

  const appContext = useAppContext();
  const { componentOptions } = appContext.useConfig();

  const [lastPlayedGames] = useLocalStorage<GameProps[] | undefined>('lastplayed:current', []);
  const showLastPlayedCarousel = componentOptions?.component_homepage_show_last_played_carousel;
  const recommendedCollection = collections.find((c) => c.code.includes('recommended')) || { games: [] };

  const flattenedData = useFlattenedGameData(lastPlayedGames || []);

  return (
    <Container ref={ref} maxWidth={false} disableGutters={true}>
      {showLastPlayedCarousel && flattenedData && flattenedData.length > 0 && (
        <CollectionSection key="my-last-played">
          <Title component="h1" variant="h1" align="left">
            <Link href="/" title={t('my_last_played', 'My Last Played')} underline="hover">
              {t('my_last_played', 'My Last Played')}
            </Link>
          </Title>
          <CarouselLayout
            pattern="1S"
            items={[...Array(12)].map(
              (_, index) =>
                [
                  ...flattenedData,
                  ...recommendedCollection.games.map((game) => ({
                    ...game,
                    trend: true,
                  })),
                ]?.[index]
            )}
          />
        </CollectionSection>
      )}
      {inView &&
        collections &&
        collections.map((collection: CategoryProps, index: number) => (
          <CollectionSection key={collection.slug}>
            <Title component="h1" variant="h1" align="left">
              <Link
                href={{
                  pathname: `/${collection?.type}/[slug]`,
                  query: { slug: collection?.slug },
                }}
                title={collection?.name || undefined}
                underline="hover"
              >
                {collection.name}
              </Link>
            </Title>
            <CarouselLayout
              pattern={index % 2 ? '1Lx2Sx2S' : '1S'}
              items={[...Array(12)].map((slot, index) => collection?.games?.[index])}
            />
          </CollectionSection>
        ))}
    </Container>
  );
};
